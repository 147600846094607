export function formatCheckoutAddress(address) {
    if (!address) {
        return ''
    }

    const {
        address: street = '',
        city = '',
        complement = '',
        district = '',
        number = '',
        postalcode = '',
        state = '',
    } = address

    const joinedBaseAddress = [
        street,
        number,
        complement,
        district,
        city,
        state,
    ]
        .filter((data) => !!data)
        .join(', ')

    return `${joinedBaseAddress} - ${postalcode}`
}