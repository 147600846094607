import React, { useEffect, useState, } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import TimeWarning from './TimeWarning';
import ReceiptForm from './ReceiptForm';
import checkoutApi from 'api/requests/checkout';
import customFieldsApi from 'api/requests/customFields';
import { useCheckout } from 'context/checkout/Checkout';
import Loader from 'components/_UI/Loader';
import { useCollapse } from 'context/general/Collapse';
import Button from 'components/_UI/Button';
import { useEvent } from 'context/events/Event';
import MainWallet from 'modules/users/wallet/MainWallet';
import regex from 'helpers/regex';
import Modal from 'components/_UI/Modal';
import { Row } from 'styled/alignment/Row';
import { Column } from 'styled/alignment/Column';
import { CheckCircle } from 'react-feather';
import Text from 'components/_UI/Text';
import buttonText from 'texts/buttons/buttonText';

export default function FreePaymentForm({ event, tickets }) {
  const { checkout, setCheckout } = useCheckout();
  const { collapse, setCollapse } = useCollapse();

  const [trigger, setTrigger] = useState(0);
  const [receiptFormData, setReceiptFormData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorsAddress, setErrorsAddress] = useState({ number: null });
  const [time, setTime] = useState(null)
  const [errorRequest, setErrorRequest] = useState('')
  const [checkoutSuccess, setCheckoutSuccess] = useState('')
  const [customFields, setCustomFields] = useState([])

  function onReceiptFormCompleted(receiptForm) {
    setReceiptFormData(receiptForm);
  }

  function handleSubmit() {
    window.trigger = Math.random(Infinity);
    setTrigger((previousTrigger) => previousTrigger + 1)
  }

  async function onSubmit() {
    if (!receiptFormData) {
      return;
    }

    if (checkout?.address?.street?.postalcode) {
      if (!checkout?.address?.number) {
        setErrorsAddress({ ...errorsAddress, number: 'O campo número é obrigatório!' });
        return
      }
    }

    setLoading(true);

    const payload = {
      customer: createCustomerPayload(receiptFormData),
    };

    try {
      const { data } = await checkoutApi.checkoutOrder(payload, checkout?.orderId);

      if (data?.status === 'approved') {
        setCheckoutSuccess(true);
      }
    } catch (error) {
      setErrorRequest('Ocorreu um erro, verifique os dados informados e tente novamente.')
    } finally {
      setReceiptFormData(false)
      setLoading(false);
    }
  }

  function createCustomerPayload(data) {
    const { firstName, lastName, phone, address, document, guests } = data;

    if (guests.length) {
      return {
        first_name: firstName,
        last_name: lastName,
        phone,
        document: document.replaceAll(regex.nonDigit, ''),
        address: createAddressPayload(checkout.address),
        guests
      }
    }

    return {
      first_name: firstName,
      last_name: lastName,
      phone,
      document: document.replaceAll(regex.nonDigit, ''),
      address: createAddressPayload(address),
      guests: []
    }
  }

  function createAddressPayload(data) {
    const payload = {
      postalcode: data?.street?.postalcode,
      address: data?.street?.name,
      number: data?.number.toString(),
      district: data?.street?.district,
      state: data?.street?.city?.state?.uf,
      city: data?.street?.city?.name,
      complement: data?.complement,
      country: 'Brasil'
    }

    if (!payload.complement) {
      delete payload.complement
    }

    return payload
  }

  function goToWallet() {
    setCollapse({
      ...collapse,
      arrowPosition: false,
      componentToRender: () => <MainWallet />
    })
  }

  function goToHome() {
    setCollapse({
      ...collapse,
      height: 'min',
      withContent: false,
    })
  }

  async function getCustomFields() {
    const response = await customFieldsApi.get(event.slug)
    setCustomFields((prev) => {
      return [
        ...prev,
        ...response?.data?.data
      ]
    })
  }

  useEffect(() => {
    onSubmit()
  }, [receiptFormData])

  useEffect(() => {
    getCustomFields()
  }, [])

  return (
    <>
      <FormContent>
        <TimeWarning setTime={setTime} />

        <ReceiptForm
          trigger={trigger}
          tickets={tickets}
          customFields={customFields}
          setErrorsAddress={setErrorsAddress}
          errorsAddress={errorsAddress}
          onReceiptFormCompleted={onReceiptFormCompleted}
          hasDocument={true}
        />

        {
          loading
            ? <Loader />
            : (
              <Button
                marginTop={'35px'}
                marginBottom={'30px'}
                // variant={errorRequest ? 'disabled' : 'featured'}
                onClick={handleSubmit}
              >
                Finalizar compra
              </Button>
            )
        }

        {
          errorRequest && <Text color={colors.danger} paddingLeft={'6px'}>{errorRequest}</Text>
        }

        {
          checkoutSuccess && (
            <Modal isOpen={checkoutSuccess} closeButton={false}>
              <Row fullyCentralized>
                <CheckCircle color={colors.successDark} />
                <Text name='highlight' paddingBottom={0} marginLeft={'5px'} color={colors.successDark}>
                  Compra realizada com sucesso
                </Text>
              </Row>

              <Text marginTop={'10px'}>Você receberá um e-mail em breve com todas as informações sobre o seu pedido, incluindo detalhes do ingresso/produto adquirido e instruções adicionais.</Text>
              <Text marginTop={'10px'}>Agradecemos pela sua compra e esperamos que você aproveite ao máximo sua experiência!</Text>

              <RowButtons>
                <Button
                  variant='light'
                  marginRight={'12px'}
                  onClick={goToHome}

                >
                  {buttonText.close}
                </Button>

                <Button variant='lightBlue' onClick={goToWallet}>
                  {buttonText.seeWallet}
                </Button>
              </RowButtons>
            </Modal>
          )
        }
      </FormContent>
    </>
  );
}

const FormContent = styled.form`
  width: 100%;
  padding: 10px 30px;
  background-color: ${colors.neutralLight};
`;

const RowButtons = styled(Row)`
  width: 100%;
  margin-top: 20px;

  & > button {
    box-shadow: unset;
  }
`;