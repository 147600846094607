import React from 'react'
import { Row } from 'styled/alignment/Row'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import { Mail, MapPin } from 'react-feather';
import Text from 'components/_UI/Text';
import { formatCheckoutAddress } from '../commom/formatCheckoutAddress';

export default function UserDataCard({ data, onEdit = () => false }) {
    return (
        <Container>
            <UserInfo>
                <Column width='auto' height='auto' >
                    <DynamicProfileImage size='54px' word={data?.first_name} img={data?.avatar} />
                </Column>

                <UserContent>
                    <Text>{data?.first_name}</Text>
                    <Row>
                        <Mail size={14} color={colors.night} />
                        <Text marginLeft={'4px'}>{data?.email}</Text>
                    </Row>
                    {
                        data?.checkout_address && 
                        <Row>
                            <MapPin size={14} color={colors.night} />
                            <Text marginLeft={'4px'}>{formatCheckoutAddress(data?.checkout_address)}</Text>
                        </Row>
                    }
                </UserContent>
            </UserInfo>

            <StyledEditButton onClick={onEdit}>
                <Text type='bold' color={colors.occasionalPurple} paddingBottom={0}> Editar</Text>
            </StyledEditButton>
        </Container>
    )
}


const Container = styled(Column)`
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 5px #ddd;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 14px;
    align-items: center;
    margin: 4px 0 10px 0;

    @media screen and (min-width: 375px){
        flex-direction: row;
    }
    
`;

const UserInfo = styled(Row)`
    align-items: center;
`;

const UserContent = styled(Column)`
    margin-left: 14px;
`;

const StyledEditButton = styled.div`
    padding: 0 10px;
    cursor: pointer;
`;